import { Component, OnInit, Input} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/pages/shared/confirm-dialog/confirm-dialog.component';
import { Product } from 'src/app/pages/shared/interfaces/product';
import { GlobalStateService } from 'src/app/pages/shared/service/global-state.service';
import { CommonServices } from 'src/app/services/common.service';
import { item } from 'src/app/services/models/cartitems.model';
import { category } from 'src/app/services/models/product.model';
import { MessageService } from '../../utils/messageService';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  categories:Array<category> = []
  searchType:string = 'ALLCATSONLY'
  openShop:boolean=false;
  userLogged:boolean=false;
  items: Product[];
  @Input()shopid:string;
  @Input()retailShopId:string;
  activeroute:string='home'

  constructor(private commonService:CommonServices,private router:Router , private matDialog: MatDialog,private global: GlobalStateService,public snackBar: MatSnackBar,private messageService: MessageService,private route: ActivatedRoute) {
    let User = JSON.parse(sessionStorage.getItem('userData'))
   }

  ngOnInit() {
    // this.openShop=JSON.parse(sessionStorage.getItem('openShop'))
    this.validatemenuselection()
    this.openShop = this.global.globalData.openShop
    this.userLogged = this.global.globalData.userLogged
    if(this.shopid && this.retailShopId)
    this.GetAllCategories()
   }

  validatemenuselection() {
    switch (true) {
      case this.router.url == '/home/contact':
        this.activeroute = 'aboutus'
        break;
      case this.router.url.includes('/home/products'):
        this.activeroute = 'products'
        break;
      case this.router.url == '/home':
        this.activeroute = 'home'
        break;
      case this.router.url == '/home/wishlist'||this.router.url == '/home/account/orders'||this.router.url == '/home/account/information':
        this.activeroute = 'myaccount'
        break;
      case this.router.url =='/home/faqs':
        this.activeroute = 'faq'
      case this.router.url =='/home/cart':
        this.activeroute = 'cart'
      case this.router.url =='/home/category-list':
        this.activeroute = 'category'
      default:
        this.activeroute = 'other'
        break;
    }

  }

   GetAllCategories() {
    this.commonService.getAllCatalogs(parseInt(this.shopid),parseInt(this.retailShopId)).subscribe((res)=>{
      if(res.acknowledgment.status == 'SUCCESS'){
        this.categories = res.catalogue[0].categories.category
        if(this.categories.length<=4){
          this.categories.forEach((cat)=>{
          if(!cat.category){
            this.commonService.getProducts(parseInt(this.shopid),parseInt(this.retailShopId),cat.categoryId).subscribe((res)=>{
              if(res.catalogue[0].categories.category[0].productsExistDownCategoryTree){
               cat.products= res.catalogue[0].categories.category[0].products
               this.items?.push(cat.products[0].product);
              }
            })
          }
        })
        }
        else if(this.categories.length>4){
          for (let i = 0; i < 4; i++) {
            const element =  this.categories[i];
            if(!element.category){
              this.commonService.getProducts(parseInt(this.shopid),parseInt(this.retailShopId),element.categoryId).subscribe((res)=>{
                if(res.catalogue[0].categories.category[0].productsExistDownCategoryTree){
                  element.products= res.catalogue[0].categories.category[0].products
                 this.items?.push(element.products[0].product);
                }
              })
            }
          }
        }
      }
    })
  }

  openMegaMenu(){
    let pane = document.getElementsByClassName('cdk-overlay-pane');
    [].forEach.call(pane, function (el) {
        if(el.children.length > 0){
          if(el.children[0].classList.contains('mega-menu')){
            el.classList.add('mega-menu-pane');
          }
        }
    });
  }

  NavigateToCategory(id){
    if(id){
      this.router.navigate(['/home/products'],{
        queryParams:{category:id}
      })
    }
    else{
      this.router.navigate(['home/category-list'])
    }
  }

  logOut(){
      const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
        maxWidth: "600px",
        maxHeight: "500px",
        data: {
            title: "Do you want to logout?",
            btn1: "No",
            btn2: "Yes"
           }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result === true){
          sessionStorage.clear()
          const globalData={
            "address":{},
            "paymentModes": [],
            "shops": [],
            "status": null,
            "userInfo": null,
            "warehouses": [],
            "cartdetails": { order: [{
              items:{
                item:[]
              }
            }] },
            "wishlistdetails": { order: [{
              items:{
                item:[]
              }
            }] },
            "openShop": false,
            "userLogged":false,
            "banner":[],
            "category": [
              { "title": "Category 1", "subtitle": "Description", "image": "assets/images/carousel/placeholder-banner.png", id: null,subcat:[] },
              { "title": "Category 2", "subtitle": "Description", "image": "assets/images/carousel/placeholder-banner.png", id: null,subcat:[] },
              { "title": "Category 3", "subtitle": "Description", "image": "assets/images/carousel/placeholder-banner.png", id: null,subcat:[] },
              { "title": "Category 4", "subtitle": "Description", "image": "assets/images/carousel/placeholder-banner.png", id: null,subcat:[] },
              { "title": "Category 5", "subtitle": "Description", "image": "assets/images/carousel/placeholder-banner.png", id: null,subcat:[] },
              { "title": "Category 6", "subtitle": "Description", "image": "assets/images/carousel/placeholder-banner.png", id: null,subcat:[] }]
          };
          this.global.updateGlobalData(globalData)
          this.snackBar.open('You have been logged Out!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
          this.router.navigate(['/'])
        }
        else
        return;
      })
  }

  navigateProductDetail(id:number,name:string,skuId:string){
    this.router.navigateByUrl('/', {skipLocationChange: true})
    .then(() => {
      this.router.navigate(['/home/products', id, name], {queryParams: {sku: skuId, id: id}});
    });
  }

  goToOffers(){
    window.scrollTo(0,document.body.scrollHeight);
    this.messageService.sendMessage(JSON.stringify({offers:true}))
    this.router.navigate(['/home'],{queryParams:{offers:1}})

  }

    // const currentUrl = this.route.snapshot.url;
    // console.log(currentUrl[0].path);
    // if (currentUrl[0].path === 'home') {
    //   console.log("path")
    //   this.messageService.sendMessage(JSON.stringify({offers:true}))}
    // else{
    //   console.log("there")
    //   this.router.navigate(['/home'],{queryParams:{offers:1}})
    // }
}
