import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AllCatagory } from './models/product.model';
import { environment } from 'src/environments/environment';
import { CartItems } from 'src/app/services/models/cartitems.model';
import { EditQuantityRequestModel } from '../pages/shared/interfaces/editquantity-request-model';
import { UserDataModel } from '../pages/shared/interfaces/user';
import { LoginService } from '../pages/sign-in/login.service';
import { ViewCartResponseModel } from '../pages/shared/interfaces/viewcart-response-model';
import { ProductSearchResponseModel } from '../pages/shared/interfaces/product';


@Injectable({ providedIn: 'root' })
export class CommonServices {

    APIEndpoint = environment.serviceUrl
    constructor (private http: HttpClient, public loginService: LoginService) { }

    getAllCatalogs(shopId:number,retailShopId:number){
        return this.http.get<AllCatagory>(`${this.APIEndpoint}catalog?shopId=${shopId}&retailShopId=${retailShopId}&searchType=ALLCATSONLY`)
    }

    getProducts(wareHouseStoreId:number,retailShopId:number,categoryId:string){
        return this.http.get<AllCatagory>(`${this.APIEndpoint}catalog?shopId=${wareHouseStoreId}&retailShopId=${retailShopId}&searchType=CATPRODS&categoryId=${categoryId}`)
    }

    addToCart(data:any){
        return this.http.post(`${this.APIEndpoint}addtocart/new`,data)
    }

    updateQuantity(data:any){
      return this.http.post<ViewCartResponseModel>(`${this.APIEndpoint}editquantity/new`, data, {})
    }

    getCartDetails(){
        return this.http.get<CartItems>(`${this.APIEndpoint}viewcart`)
    }

    removeProductFromCart(skuId:string){
        return this.http.post<CartItems>(`${this.APIEndpoint}removefromcart/new`,{skuId:skuId})
    }

    getWishlistItems(){
      return this.http.get<CartItems>(`${this.APIEndpoint}favprodsbyuser`)
    }

    toggleWishlist(data:any){
      return this.http.post(`${this.APIEndpoint}togglefavourite` ,data);
    }

    editQty(payload:any){
      return this.http.post(`${this.APIEndpoint}editquantity/new` ,payload)
    }



    getProductDetail(shopId:number,retailShopId:number,productId:string){
        return this.http.get(`${this.APIEndpoint}productdetail?shopId=${shopId}&&retailShopId=${retailShopId}&&productId=${productId}`)
    }

    getSearchResults(prdName: string, shopId:string,retailShopId:string) {
      return this.http.get<ProductSearchResponseModel>(environment.serviceUrl + 'prdsearch?prdName=' + prdName + '&shopId=' + shopId + '&retailShopId=' + retailShopId)
    }
  

}
