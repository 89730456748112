export const environment = {
    name:'sit',
    production: false,
    url: '',
    serviceUrl: 'https://storimart.bytetale.com/storimartservices/',
    appName: 'storimartRSSA',
    source :"EUAPP",
    logo:"../../../../../assets/images/logos/storimart.png",
    website:'https://storimart.com/',
    twitterUrl : 'https://twitter.com/',
    linkedinUrl : 'https://www.linkedin.com/company/storimart-b2b',
    facebookUrl : 'https://www.facebook.com/storimart',
    blckUrl:'http://localhost:4200/'
};
